import * as _hasown2 from "hasown";
var _hasown = _hasown2;
try {
  if ("default" in _hasown2) _hasown = _hasown2.default;
} catch (e) {}
import * as _sideChannel2 from "side-channel";
var _sideChannel = _sideChannel2;
try {
  if ("default" in _sideChannel2) _sideChannel = _sideChannel2.default;
} catch (e) {}
import * as _type2 from "es-errors/type";
var _type = _type2;
try {
  if ("default" in _type2) _type = _type2.default;
} catch (e) {}
var exports = {};
var hasOwn = _hasown;
var channel = _sideChannel();
var $TypeError = _type;
var SLOT = {
  assert: function (O, slot) {
    if (!O || typeof O !== "object" && typeof O !== "function") {
      throw new $TypeError("`O` is not an object");
    }
    if (typeof slot !== "string") {
      throw new $TypeError("`slot` must be a string");
    }
    channel.assert(O);
    if (!SLOT.has(O, slot)) {
      throw new $TypeError("`" + slot + "` is not present on `O`");
    }
  },
  get: function (O, slot) {
    if (!O || typeof O !== "object" && typeof O !== "function") {
      throw new $TypeError("`O` is not an object");
    }
    if (typeof slot !== "string") {
      throw new $TypeError("`slot` must be a string");
    }
    var slots = channel.get(O);
    return slots && slots["$" + slot];
  },
  has: function (O, slot) {
    if (!O || typeof O !== "object" && typeof O !== "function") {
      throw new $TypeError("`O` is not an object");
    }
    if (typeof slot !== "string") {
      throw new $TypeError("`slot` must be a string");
    }
    var slots = channel.get(O);
    return !!slots && hasOwn(slots, "$" + slot);
  },
  set: function (O, slot, V) {
    if (!O || typeof O !== "object" && typeof O !== "function") {
      throw new $TypeError("`O` is not an object");
    }
    if (typeof slot !== "string") {
      throw new $TypeError("`slot` must be a string");
    }
    var slots = channel.get(O);
    if (!slots) {
      slots = {};
      channel.set(O, slots);
    }
    slots["$" + slot] = V;
  }
};
if (Object.freeze) {
  Object.freeze(SLOT);
}
exports = SLOT;
export default exports;